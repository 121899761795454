/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        var viewportWidth = $(window).width();

        //alert(viewportWidth);

        // Fastclick
        FastClick.attach(document.body);

        // Remove empty p tags
        $('p:empty').remove();

        // External links
        $('a').filter(function(){
          return this.hostname && this.hostname !== location.hostname;
        }).addClass('external').attr('target', '_blank');

        // Scroll Top
        var backTop = $('.top');

        $(window).scroll(function() {
          var scrollX = $(this).scrollTop();

          if (scrollX > 500) {
            backTop.fadeIn();
          } else {
            backTop.fadeOut();
          }
        });

        backTop.click(function(e) {
          e.preventDefault();

          $('html, body').animate({
            scrollTop: 0
          }, 800);
        });

        // Sticky menu
        if (viewportWidth >= 1024) {
          var menu = $('.c-header'),
              heroBottom = $('.c-hero').outerHeight(true);

          $(window).scroll(function() {
            var scrollX = $(this).scrollTop();

            if (scrollX >= heroBottom) {
              $('c-nav').css('padding-top', menu.outerHeight(true) + 60);
              menu.addClass('is-sticky');
            } else {
              $('c-nav').css('padding-top', 0);
              menu.removeClass('is-sticky');
            }
          });
        }

        // Button w/scroll
        $('.c-btn--scroll').click(function(e) {
          e.preventDefault();

          var section = $(this).attr('href');

          $('html, body').animate({
            scrollTop: $(''+section+'').offset().top
          }, 2000);
        });

        // Menu
        $('.c-trigger--open').click(function(e) {
          e.preventDefault();

          $('.c-nav').toggleClass('is-active');
          $(this).toggleClass('is-hidden');
        });

        $('.c-trigger--close').click(function(e) {
          e.preventDefault();

          $('.c-nav').removeClass('is-active');
          $('.c-trigger--open').removeClass('is-hidden');
        });

        // Hero
        var hero        = $('.c-hero'),
            heroMobile  = hero.data('mobile');
            heroDesktop = hero.data('desktop');

        if (viewportWidth < 1024) {
          hero.css('background-image', 'url('+heroMobile+')');
        } else {
          hero.css('background-image', 'url('+heroDesktop+')');
        }

        // Testimonials
        if ($('.c-carousel--testimonials').length) {
          $('.c-carousel--testimonials').owlCarousel({
            items: 1,
            loop: true,
            dots: true,
            autoplay: true,
            autoplayTimeout: 7000,
            autoplayHoverPause: true,
            autoplaySpeed: 1000,
            autoHeight: true
          });
        }

        // Forms
        if ($('.gform_wrapper').length) {
          // Set input state
          $('.ginput_container input, .ginput_container textarea, .ginput_container select').each(function(){
            if ($(this).val() === '') {
              $(this).parents('.gfield').addClass('is-empty');
            } else {
              $(this).parents('.gfield').removeClass('is-empty').addClass('is-fill');
            }
          });

          // Check for input state
          $('.ginput_container input, .ginput_container textarea, .ginput_container select').on('change', function(){
            if ($(this).val().length) {
              $(this).parents('.gfield').removeClass('is-empty').addClass('is-fill');
            } else {
              $(this).parents('.gfield').removeClass('is-fill').addClass('is-empty');
            }
          });

          // Set input focus
          $('.ginput_container input, .ginput_container textarea, .ginput_container select').on('blur', function(){
            $(this).parents('.gfield').removeClass('is-focus');
          }).on('focus', function(){
            $(this).parents('.gfield').addClass('is-focus');
          });
        }

        // Highlights
        var highlight         = $('.c-main--background'),
            highlightMobile   = highlight.data('mobile'),
            highlightDesktop  = highlight.data('desktop');

        if (viewportWidth < 1024) {
          highlight.css('background-image', 'url('+highlightMobile+')');
        } else {
          highlight.css('background-image', 'url('+highlightDesktop+')');
        }

        // FAQs w/ accordion
        /*$('.c-faqs__question').click(function() {
          $(this).next().slideToggle().siblings('.c-faqs__answer').slideUp();
        });*/
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
              

        if (!Cookies.get('we_moved')) {
          setTimeout(function () {
            $.fancybox.open({
              src: '.popup-overlay',
              type: 'inline'
            });

            Cookies.set('we_moved', 'true', { expires: 1 });
          }, 1000);
        }
        
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // Contact page
    'contact': {
      init: function() {
        /// Google Map
        /*var map,
            geocoder = new google.maps.Geocoder(),
            infowindow = new google.maps.InfoWindow(),
            coordinates = [34.0620079, -118.3521759];

        var marker_icon = '/app/themes/sage/dist/images/marker.svg';

        //define the basic color of your map, plus a value for saturation and brightness
        var main_color = '#f7f7f7',
            saturation_value = -100,
            brightness_value = 10;

        //we define here the style of the map
        var style= [
          {
            //set saturation for the labels on the map
            elementType: "labels",
            stylers: [
              {saturation: saturation_value}
            ]
          },
            { //poi stands for point of interest - don't show these lables on the map
            featureType: "poi",
            elementType: "labels",
            stylers: [
              {visibility: "off"}
            ]
          },
          {
            //don't show highways lables on the map
                featureType: 'road.highway',
                elementType: 'labels',
                stylers: [
                    {visibility: "off"}
                ]
            },
          {
            //don't show local road lables on the map
            featureType: "road.local",
            elementType: "labels.icon",
            stylers: [
              {visibility: "off"}
            ]
          },
          {
            //don't show arterial road lables on the map
            featureType: "road.arterial",
            elementType: "labels.icon",
            stylers: [
              {visibility: "off"}
            ]
          },
          {
            //don't show road lables on the map
            featureType: "road",
            elementType: "geometry.stroke",
            stylers: [
              {visibility: "off"}
            ]
          },
          //style different elements on the map
          {
            featureType: "poi.sport_complex",
            elementType: "geometry.fill",
            stylers: [
              { visibility: "off" }
            ]
          },
          {
            featureType: "landscape",
            stylers: [
              { hue: main_color },
              { visibility: "on" },
              { lightness: brightness_value },
              { saturation: saturation_value }
            ]

          },
          {
            featureType: "road",
            elementType: "geometry.fill",
            stylers: [
              { hue: main_color },
              { visibility: "on" },
              { lightness: brightness_value },
              { saturation: saturation_value }
            ]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [
              { hue: main_color },
              { visibility: "on" },
              { lightness: brightness_value },
              { saturation: saturation_value }
            ]
          },
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [
              { hue: main_color },
              { visibility: "on" },
              { lightness: brightness_value },
              { saturation: saturation_value }
            ]
          }
        ];

        function initialize(){
          var latLng = new google.maps.LatLng(coordinates[0], coordinates[1]);

          var map = new google.maps.Map(document.getElementById('map-canvas'),{
            disableDefaultUI: true,
            draggable: true,
            scrollwheel: true,
            zoom: 17,
            zoomControl: true,
            center: latLng,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            styles: style
          });

          geocoder.geocode({ "latLng": latLng }, function(results, status){
            if(status === google.maps.GeocoderStatus.OK){
              google.maps.event.addDomListener(window, 'resize', function(){
                map.setCenter(results[0].geometry.location);
              });

              var marker = new google.maps.Marker({
                map: map,
                position: results[0].geometry.location,
                icon: marker_icon
              });

              var content = "<h3>Global Access</h3>"+
                            "<p>"+results[0].formatted_address+"</p>";

              infowindow.setContent(content);

              infowindow.open(map,marker);

              google.maps.event.addListener(marker, 'click', function(){
                infowindow.open(map,marker);
              });
            }
          });
        }
        google.maps.event.addDomListener(window, 'load', initialize);*/
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
